import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import b1 from './images/b1.jpeg'
import b2 from './images/b2.jpeg'
import b3 from './images/b3.jpeg'
import b4 from './images/b4.jpeg'
import bg from './images/cri.jpg'
import l1 from './images/d.png'
import l2 from './images/7.png'
import l3 from './images/s.jpg'
import l4 from './images/s.png'
import l5 from './images/v.png'
import b5 from './images/b5.jpeg'
import './Home.css'

function Home() {

  const trackQuery = (category, action, label) => {
    console.log(`Tracking event: ${category}, ${action}, ${label}`);
    
  };

  return (
    <div>
      <body data-aos-easing="ease" data-aos-duration="1000" data-aos-delay="0">
        <header>
          <div className="header-top-area s-header-top-area d-none d-lg-block">
            <div className="container-fluid s-container-full-padding">
              <div className="row align-items-center">
                <div className="col-lg-6 d-none d-lg-block">
                  <div className="header-top-offer">
                    <p>Get Trusted Online ID Here</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="header-top-right">
                    <div className="header-social">
                      <ul className="sm-megamenu-hover sm_megamenu_menu sm_megamenu_menu_black">
                        <li><a href="https://thebharatbook.co.in/index">Home</a></li>              
                        <li><a href="https://t.me/+mgc0sUc6OZhlZGNl">Telegram</a></li>
                        <li><a href="https://www.instagram.com/balaji_online_book_24_7/profilecard/?igsh=MWR3djVrNHplYnRjaQ==">Privacy Policy</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
 <a
    target="_blank"
    
    onClick={() => trackQuery('Displayad', 'Displayad', 'Displayad')}
    className="enq-side-btn-icone"
>
    <i className="fab fa-whatsapp" style={{ fontSize: '24px', marginRight: '8px' }}></i>
   whatsapp Now
</a>







<section
  className="slider-area slider-bg"
  style={{
    backgroundColor: 'black',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  }}
>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-9">
        <div className="slider-content text-center">
          <div className="banners">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <a target="_blank">
                  <img
                    src={b2}
                    alt="Banner 2"
                    width="100%"
                    className="img-fluid"
                   
                  />
                </a>
              </div>
              <div className="col-md-4 col-sm-6">
                <a target="_blank">
                  <img
                    src={b3}
                    alt="Banner 3"
                    width="100%"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="col-md-4 col-sm-6">
                <a target="_blank">
                  <img
                    src={b4}
                    alt="Banner 4"
                    width="100%"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>

          <h6
            className="wow fadeInDown"
            data-wow-delay=".2s"
            style={{
              visibility: 'visible',
              animationDelay: '0.2s',
              animationName: 'fadeInDown',
            }}
          >
            Balaji online Booking - Get your Trusted Online Id
          </h6>
          <br />
          <a
            target="_blank"
           
            onClick={() => trackQuery('Displayad', 'Displayad', 'Displayad')}
            className="btn wow fadeInUp animated"
            data-wow-delay="2.2s"
            style={{
              visibility: 'visible',
              animationDelay: '2.2s',
              animationName: 'glowing',
            }}
          >
            Get Your Id Now
          </a>

          <h2 className="tlt fix" data-in-effect="fadeInLeft">
            <span>Trusted Online Cricket ID Provider</span>
          </h2>
          <p
            className="wow fadeInUp"
            data-wow-delay="2s"
            style={{
              visibility: 'visible',
              animationDelay: '2s',
              animationName: 'fadeInUp',
            }}
          >
            India's no. 1 online sports games promoter. Get your ID within 2 minutes! Join the Most Trusted, Secure & Fastest Book of India.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>




<section className="game-single-area pt-120 pb-120">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="game-single-content">
          <div className="upcoming-game-head">
            <div className="uc-game-head-title">
              <h4>Welcome in The Family of <span>Balaji Booking - Oldest Fantasy Sports provider</span></h4>
            </div>
            <a
              target="_blank"
             
              onClick={() => trackQuery('Displayad', 'Displayad', 'Displayad')}
            >
              <div className="uc-game-price">
                <h5>Get Your ID Now</h5>
              </div>
            </a>
          </div>
          <p>
            Get in touch with Balaji Booking for any Queries, Emergencies, Feedback, or Complaints. We are here to help you 24*7 with our services on WhatsApp.
          </p>
          <p>
            Welcome to Balaji Booking, we offer you a genuinely unique fantasy sports experience. Besides, We have a global network and provide the best industry experience with a single click.
          </p>
          <p>
            Balaji Booking is a responsible fantasy sports promoter. We deal in all kinds of fantasy sports games. We have helped 50000+ players to find the best fantasy sports online.
          </p>
          <p>Our Company Works In A Very Transparent Way. Get Your Demo ID Now</p>

          <div className="row">
            <div className="col-md-6">
              <div className="game-single-title mt-60 mb-30">
                <h4>Why Choose Us</h4>
              </div>
              <div className="game-single-info mb-65">
                <ul>
                  <li>24*7 Support</li>
                  <li>Instant Reply</li>
                  <li>Most Trusted &amp; Secure Platform</li>
                  <li>5 Lac+ Active Users</li>
                  <li>Working Since 2010</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <img className="img-thumbnail" src={b1} alt="Fantasy Sports" style={{ maxWidth: '100%' }} />
            </div>
          </div>

          <div className="row mb-50">
            <div className="col-md-6 col-sm-12 mb-4">
              <a target="_blank">
                <img className="img-thumbnail" src={b5} alt="Banner 1" style={{ maxWidth: '100%' }} />
              </a>
            </div>
            <div className="col-md-6 col-sm-12 mb-4">
              <a target="_blank">
                <img className="img-thumbnail" src={b2} alt="Banner 2" style={{ maxWidth: '100%' }} />
              </a>
            </div>
          </div>

          <div className="footer-widget mb-50">
            <div className="fw-title mb-35">
              <h5 className="text-primary">Our Partners - Website Available</h5>
            </div>
            <div className="container-fluid">
              <ul className="row">
                <li className="col-md-3">
                  <a href="">
                    <img className="img-circle" src={l1} alt="uk247" style={{ maxWidth: '100%', overflow: 'hidden' }} />
                  </a>
                </li>
                <li className="col-md-3">
                  <a href="">
                    <img className="img-circle" src={l5} alt="icebook9" style={{ maxWidth: '100%', overflow: 'hidden' }} />
                  </a>
                </li>
                <li className="col-md-3">
                  <a href="">
                    <img className="img-circle" src={l3} alt="uk777" style={{ maxWidth: '100%', overflow: 'hidden' }} />
                  </a>
                </li>
                <li className="col-md-3">
                  <a href="">
                    <img className="img-circle" src={l4} alt="Skyexchang" style={{ maxWidth: '100%', overflow: 'hidden' }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-widget mb-50">
            <div className="fw-title mb-35">
              <h5 className="text-primary">Explore Our Games</h5>
            </div>
            <div className="container-fluid">
              <div className="icons text-center d-flex justify-content-center flex-wrap">
                <div className="d-inline-block mx-4 my-3 text-center">
                  <span style={{ fontSize: '3rem', color: 'Black', display: 'block' }}>🏏</span>
                  <span style={{ fontSize: '2rem', color: 'Black' }}>Cricket</span>
                </div>
                <div className="d-inline-block mx-4 my-3 text-center">
                  <span style={{ fontSize: '3rem', color: 'Black', display: 'block' }}>⚽</span>
                  <span style={{ fontSize: '2rem', color: 'Black' }}>Football</span>
                </div>
                <div className="d-inline-block mx-4 my-3 text-center">
                  <span style={{ fontSize: '3rem', color: 'Black', display: 'block' }}>🎾</span>
                  <span style={{ fontSize: '2rem', color: 'Black' }}>Tennis</span>
                </div>
                <div className="d-inline-block mx-4 my-3 text-center">
                  <span style={{ fontSize: '3rem', color: 'Black', display: 'block' }}>🎰</span>
                  <span style={{ fontSize: '2rem', color: 'Black' }}>Casino</span>
                </div>
                <div className="d-inline-block mx-4 my-3 text-center">
                  <span style={{ fontSize: '3rem', color: 'Black', display: 'block' }}>🃏</span>
                  <span style={{ fontSize: '2rem', color: 'Black' }}>Live Poker</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>






        </main>

        <footer>
          <div className="footer-top footer-bg s-footer-bg" style={{backgroundColor:'black'}}>
            <div className="con">
              <ul className="sm-megamenu-hover sm_megamenu_menu sm_megamenu_menu_black sdfgfd" style={{width:'40vh'}} >
                <li className='m' style={{marginLeft:'180vh'}}><a href="">Terms and conditions</a></li>
                <li className='m'><a href="">Game Addiction</a></li>
                <li className='m'><a href="">Privacy Policy</a></li>
              </ul>
            </div>
           
  <ul className="sm-megamenu-hover sm_megamenu_menu sm_megamenu_menu_black sdfgfd">
    <li>
      <a href="https://t.me/+mgc0sUc6OZhlZGNl" className="social-link">
        <i className="fab fa-telegram-plane"></i> Telegram
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/balaji_online_book_24_7/profilecard/?igsh=MWR3djVrNHplYnRjaQ==" className="social-link">
        <i className="fab fa-instagram"></i> Instagram
      </a>
    </li>
  </ul>


          </div>
        </footer>
        

      </body>
    </div>
  );
}

export default Home;
