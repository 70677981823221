import React from "react";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
     
      <Home/>
     
    </div>
  );
}

export default App;
